import {
  authStore,
  getToken,
  saveLastinSyncCalendar,
} from "./utilities/authStore.js";

import { tokenCheckWithServer } from "./utilities/requests/get-startup.js";
import { Router } from "@vaadin/router";
import { filterPaths } from "./routes";
import { defer, from } from "rxjs";

checkNetlifyBuild();

window.addEventListener("vaadin-router-error", (e) => {
  console.log("vaadin-router-go event, error:", e);
  if (e.detail?.error) {
    console.log("error in the router: ", e.detail.error);
    console.log("error message in the router: ", e.detail.error?.message);
    if (e.detail.error?.message.includes("is not properly resolved")) {
      // window.location.reload(true);
    }

    if (!authStore.token.value) {
      console.log("No token found, clearing localStorage and redirecting to /auth");
      localStorage.clear();
      window.location = "/auth.html";
    }
  }
});

/* 
  Set a class on the body element based on the current page.
  This allows us to apply page-specific styles.
*/
window.addEventListener("vaadin-router-location-changed", (e) => {
  const { pathname } = e.detail.location;
  const currentPage = pathname.split("/")[1] || "home";
  const className = `page-${currentPage}`;

  document.body.classList.forEach((item) => {
    if (item.startsWith("page-")) document.body.classList.remove(item);
  });

  document.body.classList.add(className);
});

let prompter;
let timer;
let promptTimer;
let sse;
let token = getToken();

let headerImport, sidebarImport, mentoringListenersImport;

if (!authStore.token.value) {
  console.log("No token found, clearing localStorage and redirecting to /auth");
  localStorage.clear();
  window.location = "/auth.html";
} else {
  console.log("Token found: ", token);

  import(
    /* webpackPrefetch: true */ "./components/prompt-drawer/prompt-drawer.js"
  )
    .then(() => {
      console.log("prompt-drawer is loaded");
    })
    .catch((error) => {
      console.log("Error in dynamic import prompt-drawer:", error);
    });

  if (isMobile()) {
    require.ensure(
      ["./components/menu-bottom/menu-bottom.js"],
      function (require) {
        const MenuBottom = require("./components/menu-bottom/menu-bottom.js");
        console.log("menu-bottom is loaded with require.ensure", MenuBottom);
      },
      function (error) {
        console.log(
          "Error in dynamic import menu-bottom with require.ensure:",
          error
        );
      }
    );
    import(/* webpackPreload: true */ "@moduware/morph-sidebar")
      .then(() => {
        console.log("@moduware/morph-sidebar is loaded");
      })
      .catch((error) => {
        console.log("Error in dynamic import @moduware/morph-sidebar:", error);
      });
  }

  headerImport = defer(() =>
    from(import(/* webpackPreload: true */ "./components/header/header.js"))
  );
  sidebarImport = defer(() =>
    from(import(/* webpackPreload: true */ "./components/sidebar/sidebar.js"))
  );

  mentoringListenersImport = defer(() =>
    from(
      import(
        /* webpackPreload: true */ "./components/mentoring-listeners/mentoring-listeners.js"
      )
    )
  );
}

// import(/* webpackPreload: true */ "./components/prompt-drawer/prompt-drawer.js")
//   .then(() => {
//     console.log("prompt-drawer is loaded");
//   })
//   .catch((error) => {
//     console.log("Error in dynamic import prompt-drawer:", error);
//   });

// import(/* webpackPreload: true */ "./components/menu-bottom/menu-bottom.js")
//   .then(() => {
//     console.log("menu-bottom is loaded");
//   })
//   .catch((error) => {
//     console.log("Error in dynamic import menu-bottom:", error);
//   });

// require.ensure(
//   ["./components/menu-bottom/menu-bottom.js"],
//   function (require) {
//     const MenuBottom = require("./components/menu-bottom/menu-bottom.js");
//     console.log("menu-bottom is loaded with require.ensure", MenuBottom);
//   },
//   function (error) {
//     console.log("Error in dynamic import menu-bottom with require.ensure:", error);
//   }
// );

// import(/* webpackPreload: true */ "@moduware/morph-sidebar")
//   .then(() => {
//     console.log("@moduware/morph-sidebar is loaded");
//   })
//   .catch((error) => {
//     console.log("Error in dynamic import @moduware/morph-sidebar:", error);
//   });

// const headerImport = defer(() =>
//   from(import(/* webpackPreload: true */ "./components/header/header.js"))
// );
// const sidebarImport = defer(() =>
//   from(import(/* webpackPreload: true */ "./components/sidebar/sidebar.js"))
// );

// const mentoringListenersImport = defer(() =>
//   from(
//     import(
//       /* webpackPreload: true */ "./components/mentoring-listeners/mentoring-listeners.js"
//     )
//   )
// );

let globalroutes = [
  {
    path: "/threads/:id",
    action: async (ctx) => {
      if (!authStore.token.value) {
        window.location.href = "/auth.html";
      }

      posthog?.capture("$pageview", { pathname: ctx.pathname });
      await import("./pages/threads/thread/thread-page.js")
        .then(() => console.log("/thread-page is loaded"))
        .catch((error) => {
          console.log("error in import thread-page: ", error);
          if (error && error?.message.includes("failed")) {
            // window.location.reload(true);
          }
        });
    },
    component: "thread-page",
  },
  {
    path: "/threads",
    action: async (ctx) => {
      if (!authStore.token.value) {
        window.location.href = "/auth.html";
      }

      posthog?.capture("$pageview", { pathname: "/threads" });
      await import("./pages/threads/main/threads-page.js")
        .then(() => console.log("/threads-page is loaded"))
        .catch((error) => {
          console.log("error in import threads-page: ", error);
          if (error && error?.message.includes("failed")) {
            // window.location.reload(true);
          }
        });
    },
    component: "threads-page",
  },
  {
    path: "/editor-react/(.*)",
    action: (ctx, commands) => {
      posthog?.capture("$pageview");
      window.location.pathname = ctx.pathname;
    },
  },
  {
    path: "/auth/(.*)",
    action: (ctx, commands) => {
      posthog?.capture("$pageview");
      window.location.pathname = ctx.pathname;
    },
  },
  {
    path: "/logout",
    action: async (context, commands) => {
      if (!authStore.token.value) {
        window.location = "/auth.html";
      }
      console.log("LOGOUT BEING CALLED");
      await import("./pages/logout.js");
      posthog?.capture("$pageview");
    },
    component: "logout-page",
  },
  {
    path: "/",
    action: async (context, commands) => {
      if (!authStore.token.value) {
        window.location = "/auth.html";
        console.log("redirect to auth");
        return;
      }
      await import("./pages/home-page/home-page.js")
        .then(() => console.log("/home-page is loaded"))
        .catch((error) => {
          console.log("error in import homepage: ", error);
          if (error && error?.message.includes("failed")) {
            // window.location.reload(true);
          }
        });
      // await import("./pages/calendar-page/calendar-page.js")
      //   .then(() => console.log("/calendar-page is loaded"))
      //   .catch((error) => {
      //     console.log("error in import calendar-page: ", error);
      //     if (error && error?.message.includes("failed")) {
      //       // window.location.reload(true);
      //     }
      //   });
    },
    // component: "calendar-page",
    component: "home-page",
  },
  {
    path: "/calendar",
    action: async (context, commands) => {
      if (!authStore.token.value) {
        window.location = "/auth.html";
        console.log("redirect to auth");
        return;
      }
      return await import("./pages/calendar-page/calendar-page.js").then(
        (module) => {
          return new module.Calendar();
        }
      );
    },
    component: "calendar-page",
  },
  {
    path: "/calendar/public-event/:id",
    action: async (context, commands) => {
      // WIP
      if (!authStore.token.value) {
        window.location = "/auth.html";
      }
      return await import(
        "./pages/share-public-event/share-public-event.js"
      ).then((module) => {
        return new module.SharePublicEvent(context.params.id);
      });
    },
  },
  {
    path: "/calendar/private-event/:id",
    action: async (context, commands) => {
      if (!authStore.token.value) {
        window.location = "/auth.html";
      }

      return await import(
        "./pages/share-private-event/share-private-event.js"
      ).then((module) => {
        return new module.SharePrivateEvent(context.params.id);
      });
    },
  },
  {
    path: "/startups",
    action: async (context, commands) => {
      if (!authStore.token.value) {
        window.location = "/auth.html";
      }

      // open startup page from mentor side
      let preSelectedStartup = "";
      if (context.search.includes("startup")) {
        preSelectedStartup = context.search.split("=")[1];
      }
      return await import("./pages/startup-directories/startup-directories.js")
        .then((module) => {
          console.log("/startup-directories is loaded");
          return new module.StartupDirectories(preSelectedStartup);
        })
        .catch((error) => {
          console.log("error in import startup-directories: ", error);
          if (error && error?.message.includes("failed")) {
            // window.location.reload(true);
          }
        });
    },
  },
  {
    path: "/mentoring",
    action: async (context, commands) => {
      if (!authStore.token.value) {
        window.location = "/auth.html";
      }

      console.log("RESOLVING STARTUPS ON MOBILE");

      if (authStore.userRole.value === "mentor") {
        return commands.redirect("/mentor-community");
      }

      // to open the tab when redirect from calendar page
      let tabMentoring;
      if (context.search === "?sessions") {
        tabMentoring = true;
      }
      return await import("./pages/mentoring/mentoring.js")
        .then((module) => {
          console.log("/mentoring is loaded");
          return new module.MentoringPage(tabMentoring);
        })
        .catch((error) => {
          console.log("error in import mentoring: ", error);
          if (error && error?.message.includes("failed")) {
            // window.location.reload(true);
          }
        });
    },
  },
  {
    path: "/startups/startup/:id",
    action: async (context, commands) => {
      if (!authStore.token.value) {
        window.location = "/auth.html";
      }
      await import("./components/startup-menu-bottom/startup-menu-bottom.js");
      return await import("./pages/startup-page/startup-page.js")
        .then((module) => {
          console.log("/startup-page is loaded");
          return new module.StartupPage(context.params.id);
        })
        .catch((error) => {
          console.log("error in import startup-page: ", error);
          if (error && error?.message.includes("failed")) {
            // window.location.reload(true);
          }
        });
    },
  },
  {
    path: "/mentor-community",
    action: async (context, commands) => {
      if (!authStore.token.value) {
        window.location = "/auth.html";
      }

      // get current minutes for mentor
      let mentor = authStore.userObject.value;

      let activeProgramme = mentor.programmes.find((item) => {
        return item.programme.id === authStore.programmeId.value;
      });

      // available minutes per programme
      let mentorAvailableMinutes = activeProgramme?.totalMentorAvailableMinutes;
      let mentorTotalMinutes = activeProgramme?.totalMentorMinutes;
      let mentorSessionDuration = activeProgramme?.sessionDuration;
      let typeMinutesFounder =
        activeProgramme?.programme?.settings?.founderAllowanceType ||
        "programme";
      let typeMinutesMentor =
        activeProgramme?.programme?.settings?.mentorAllowanceType;

      const sessionMinutes =
        authStore.userObject.value.mentorMentoringProgrammeTopicMinutes;
      console.log(sessionMinutes, "sessionMinutes", authStore.userObject.value);

      posthog?.capture("$pageview", {
        pathname: "/mentoring-community",
        sessionMinutes,
        mentorAvailableMinutes,
        mentorTotalMinutes,
      });
      console.log("redirected page mentor");
      return await import("./pages/mentor-community/mentor-community.js")
        .then((module) => {
          console.log("/mentor-community is loaded");
          return new module.MentorCommunity(
            sessionMinutes,
            mentorAvailableMinutes,
            mentorTotalMinutes,
            mentorSessionDuration,
            typeMinutesFounder,
            typeMinutesMentor
          );
        })
        .catch((error) => {
          console.log("error in import mentoring-community: ", error);
          if (error && error?.message.includes("failed")) {
            // window.location.reload(true);
          }
        });
    },
  },
  {
    path: "/settings-page",
    action: async (context, commands) => {
      if (!authStore.token.value) {
        window.location = "/auth.html";
      }
      let tab;
      let currentProgrammeId = authStore.programmeId.value;
      let code;

      if (
        authStore.userRole.value === "startup" &&
        context.search === "?tab=editor"
      ) {
        tab = 4;
      } else {
        if (context.search.includes("?connection=calendly")) {
          tab = 6;
        }

        if (context.search.includes("code")) {
          code = context.search.split("code=")[1];
        }

        if (context.search === "?tab=editor") {
          tab = 4;
        }
      }

      // for redirection to edit event (from calendar page)
      let eventId;
      if (context.search.includes("edit-event")) {
        eventId = context.search.split("edit-event=")[1];
        tab = 16;
      }

      // for edit founder / startup
      if (
        authStore.userRole.value === "startup" &&
        context.search.includes("tab=edit")
      ) {
        if (context.search.includes("founder")) {
          tab = 9;
        } else if (context.search.includes("startup")) {
          tab = 10;
        }
      }

      return await import(
        /* webpackPrefetch: true */ "./pages/settings-page/settings-page.js"
      )
        .then((module) => {
          console.log("/settings-page is loaded");
          return new module.SettingsPage(
            tab,
            code,
            currentProgrammeId,
            authStore.userRole.value,
            eventId
          );
        })
        .catch((error) => {
          console.log("error in import settings-page: ", error);
          if (error && error?.message.includes("failed")) {
            // window.location.reload(true);
          }
        });
    },
    component: "settings-page",
  },
  {
    path: "/resources",
    action: async (context, commands) => {
      if (!authStore.token.value) {
        window.location = "/auth.html";
      }
      if (
        authStore.userRole.value === "mentor" &&
        authStore.userId.value !== "66dec5377b6808625558ece2"
      ) {
        return commands.redirect("/");
      }
      await import(/* webpackPrefetch: true */ "./pages/resources.js")
        .then(() => console.log("/resources is loaded"))
        .catch((error) => {
          console.log("error in import resources: ", error);
          if (error && error?.message.includes("failed")) {
            // window.location.reload(true);
          }
        });
    },
    component: "resources-page",
  },
  {
    path: "/resource/:id",
    action: async (context, commands) => {
      if (!authStore.token.value) {
        window.location = "/auth.html";
      }
      if (
        authStore.userRole.value === "mentor" &&
        authStore.userId.value !== "66dec5377b6808625558ece2"
      ) {
        return commands.redirect("/");
      }
      posthog?.capture(
        "$pageview",
        { pathname: "/resource/:id" },
        { resourceId: `${context.params.id}` }
      );
      await import(
        /* webpackPrefetch: true */ "./pages/resource-page/resource-page.js"
      )
        .then(() => console.log("/resource-page is loaded"))
        .catch((error) => {
          console.log("error in import resource-page: ", error);
          if (error && error?.message.includes("failed")) {
            // window.location.reload(true);
          }
        });
    },
    component: "resource-page",
  },
  {
    path: "/inbox",
    action: async () => {
      if (!authStore.token.value) {
        window.location = "/auth.html";
      }

      posthog?.capture("$pageview", { pathname: "/inbox" });
      await import("./pages/inbox-mobile-page.js")
        .then(() => console.log("/inbox-mobile-page is loaded"))
        .catch((error) => {
          console.log("error in import inbox-mobile-page: ", error);
          if (error && error?.message.includes("failed")) {
            // window.location.reload(true);
          }
        });
    },
    component: "inbox-mobile-page",
    children: [
      {
        path: "/new/:userId",
        component: "inbox-new-chat",
        action: async () => {
          posthog?.capture("$pageview", { pathname: "/inbox/new/:userId" });
          await import("../global-components/inbox/inbox-new-chat.js")
            .then(() => console.log("/inbox/new/:userId is loaded"))
            .catch((error) => {
              console.log("error in import inbox/new/:userId: ", error);
              if (error && error?.message.includes("failed")) {
                // window.location.reload(true);
              }
            });
        },
      },
      {
        path: "/:id",
        component: "inbox-feed",
        action: async () => {
          posthog?.capture("$pageview", { pathname: "/inbox/:id" });
          await import("../global-components/inbox/inbox-feed.js")
            .then(() => console.log("/inbox/:id is loaded"))
            .catch((error) => {
              console.log("error in import inbox/:id: ", error);
              if (error && error?.message.includes("failed")) {
                // window.location.reload(true);
              }
            });
        },
      },
    ],
  },
];
export const appRouter = new Router(document.querySelector("#app"), {
  baseUrl: "/",
});

if (!authStore.token.value) {
  appRouter.setRoutes(filterPaths(globalroutes, authStore.token.value));
  console.log("No token found, clearing localStorage and redirecting to /auth");
  localStorage.clear();
  window.location = "/auth.html";
} else {
  tokenCheckWithServer();
  //A CHECK WE HAD A WHILE AGO
  if (authStore.userObject.value && authStore.userRole.value !== "mentor") {
    console.log("YES USER OBJECT AND NOT MENTOR");
    if (!authStore.founderId.value) {
      console.log("NO FOUNDER ID");
    }
  }

  console.log("Token found: ", token);
  appRouter.setRoutes(filterPaths(globalroutes, authStore.token.value));
  headerImport.subscribe();
  sidebarImport.subscribe();
  mentoringListenersImport.subscribe();

  window.headerComponent = document.querySelector("header-section");
  window.sidebarComponent ??= document.querySelector("sidebar-menu");
  window.menuComponent = document.querySelector("menu-bottom");
  window.menuChatComponent = document.querySelector("thread-chat-menu");
  window.menuStartupComponent = document.querySelector("startup-menu-bottom");

  checkActiveCohorts();
  // subscribeToAllUpdates();

  import(/* webpackPreload: true */ "../src/_data/prompter.js").then(
    (module) => {
      console.log("PROMPTER MODULE READY", module);
      prompter = module.default();
      // if (authStore.token.value) {
      //   promptTimer = setTimeout(() => {
      //     if (prompter) {
      //       prompter.bootUp().then(() => {
      //         console.log("prompter is boot up");
      //         //prompter.subscribeToNewData();
      //       });
      //     }
      //   }, 1500);
      // }
    }
  );

  promptTimer = setTimeout(() => {
    if (prompter && authStore.token.value) {
      prompter.bootUp().then(() => {
        console.log("prompter is boot up");
        //prompter.subscribeToNewData();
      });
    }
    subscribeToAllUpdates();
  }, 1500);
}

// check that to logout people -> to cleat their local db
const CLEAR_LOCAL_DB_SESSIONS_KEY = "clear_local_db_sessions";

if (authStore.token.value) {
  //prompter = getPrompter();
  if (window.location.pathname !== "/settings-page") {
    posthog?.capture("user-init-db-data", {
      role: authStore.userRole.value,
      programme: authStore.programmeId.value,
      founder: authStore.founderObject.value,
      userInfo: authStore.userObject.value,
    });
    posthog?.register({
      role: authStore.userRole.value,
      programme: authStore.programmeId.value,
      founder: authStore.founderObject.value,
      userInfo: authStore.userObject.value,
    });
  }
}

// check the latest build
async function checkNetlifyBuild() {
  const isProduction = process.env.NODE_ENV === "production";
  if (!isProduction) return;

  const lastDeployedBuildId = localStorage.getItem("lastDeployedBuildId");
  const attempsToLoadLatestBuild = Number(
    localStorage.getItem("attempsToLoadLatestBuild") || 0
  );

  console.log(`CHECK_BUILD: Checking the latest build...`);
  let currentBuildIdData = await getBuildId();
  console.log(
    `CHECK_BUILD: getBuildId() response -> currentBuildId:`,
    currentBuildIdData?.id
  );
  const currentBuildId = currentBuildIdData?.id;

  console.log("CHECK_BUILD: Current build ID", currentBuildId);
  console.log("CHECK_BUILD: Last deployed build ID", lastDeployedBuildId);

  if (!currentBuildId) {
    console.log("CHECK_BUILD: No build ID found. Skipping the check.");
    return;
  }

  const isDifferentBuild = currentBuildId !== lastDeployedBuildId;
  const maxAttempsReached = attempsToLoadLatestBuild.length >= 5;

  if (isDifferentBuild && !maxAttempsReached) {
    console.log("CHECK_BUILD: New build detected. Reloading the page.");
    localStorage.setItem("lastDeployedBuildId", currentBuildId);
    localStorage.setItem(
      "attempsToLoadLatestBuild",
      attempsToLoadLatestBuild + 1
    );

    // delay the refresh, because some drawer or dialog is opened
    if (authStore.delayRefreshPage.value === true) {
      // don't refresh and put the value for future update
      console.log(
        "CHECK_BUILD: DELAY REFRESH THE PAGE and SAVE VALUE TO REFRESH PAGE LATER"
      );
      authStore.refreshPage.value = true;
      return;
    } else {
      // refresh
      console.log("CHECK_BUILD: REFRESH THE PAGE");
      window.location.reload(true);
    }
  } else if (isDifferentBuild && maxAttempsReached) {
    console.log(
      "CHECK_BUILD: New build detected but max attemps reached. Not reloading the page to avoid infinite loop."
    );
  } else if (!isDifferentBuild) {
    console.log("CHECK_BUILD: No new build detected.");
    localStorage.setItem("attempsToLoadLatestBuild", 0);
  }
}

// check active cohorts
function checkActiveCohorts() {
  console.log("checkActiveCohorts");
  // all active cohorts in the programme
  let activeProgramme = authStore.userObject.value.programmes.find((item) => {
    return item.programme.id === authStore.programmeId.value;
  });

  // initial check for users (just once) with 2 cohorts -> not to have data from both cohorts in the local db -> log out them -> we can have only one active cohort per programme -> the current cohort ID will be the 0 item in the array
  let cohorts = activeProgramme.cohorts;
  console.log(cohorts, "cohorts in active programme");

  // check
  addActiveCohortId();

  let activeCohorts = activeProgramme.cohorts?.filter(
    (item) => item.isCurrent === true
  );
  console.log(
    "checking active cohort for the progarmme: ",
    activeCohorts.length,
    activeCohorts
  );
  authStore.cohort.value = activeCohorts;

  if (activeCohorts.length < 1) {
    console.log("No active cohort in the selected programme.");
    alert(
      "Your cohort is no longer running. Contact natalia@capitalenterprise.org for webinar recording links."
    );

    // clear local storage
    localStorage.clear();
    window.location.replace(`/auth.html?v=${new Date().getTime()}`);
  } else if (cohorts && cohorts.length > 1) {
    let userDataWasCleared = localStorage.getItem(
      "user_data_was_cleared_for_active_cohortId"
    );
    console.log(
      userDataWasCleared,
      "user_data_was_cleared -> from local storage"
    );

    // !userDataWasCleared or if the current cohort id
    if (!userDataWasCleared || userDataWasCleared !== activeCohorts[0]?.id) {
      if (authStore.userRole?.value !== "mentor") {
        alert(
          "Your cohort is no longer running. Contact natalia@capitalenterprise.org for webinar recording links."
        );
      } else {
        alert(
          `You need to logout and login again. One of your cohorts is no longer running.`
        );
      }

      localStorage.clear();
      window.location.replace(`/auth.html?v=${new Date().getTime()}`);
    }
  }
}

// mostly this is for the current prod build with 1 cohort -> not to logout all the users
function addActiveCohortId() {
  let activeCohortId = localStorage.getItem(
    "user_data_was_cleared_for_active_cohortId"
  );
  console.log(activeCohortId, "activeCohortId from local storage");
  if (!activeCohortId) {
    let activeCohort = authStore.programmeObject.value?.cohorts?.find(
      (item) => item.isCurrent === true
    );
    console.log(
      activeCohort,
      "activeCohort from the authStore.programmeObject"
    );
    console.log("save Active cohort Id to local storage", activeCohort.id);
    if (activeCohort)
      localStorage.setItem(
        "user_data_was_cleared_for_active_cohortId",
        activeCohort.id
      );
  }
}

import "./main.css";
import "@shoelace-style/shoelace/dist/themes/light.css";
import { setBasePath } from "@shoelace-style/shoelace/dist/utilities/base-path.js";
setBasePath("../../dist");

import "@shoelace-style/shoelace/dist/components/button/button.js";
import(
  /* webpackPrefetch: true */ "@shoelace-style/shoelace/dist/components/drawer/drawer.js"
);
import(
  /* webpackPrefetch: true */ "@shoelace-style/shoelace/dist/components/select/select.js"
);
import(
  /* webpackPrefetch: true */ "@shoelace-style/shoelace/dist/components/option/option.js"
);
import { getBuildId } from "./utilities/requests/get-build-id.js";
import { isMobile } from "./utilities/isMobile.js";
import { sessionsStore } from "./utilities/sessionsStore.js";
import { eventsStore } from "./utilities/eventsStore.js";

//subscribe to all of the events related to visibility
const getState = () => {
  if (document.visibilityState === "hidden") {
    authStore.userActiveStatus.value = false;
    console.log("userActiveStatus!!", authStore.userActiveStatus.value);
    return "hidden";
  }
  if (document.hasFocus()) {
    authStore.userActiveStatus.value = true;
    console.log("userActiveStatus!!", authStore.userActiveStatus.value);
    return "active";
  }
  authStore.userActiveStatus.value = false;
  console.log("userActiveStatus!!", authStore.userActiveStatus.value);
  return "passive";
};

let displayState = getState();

const onDisplayStateChange = async (event) => {
  const nextState = getState();
  const prevState = displayState;

  if (nextState !== prevState) {
    console.log(`State changed: ${prevState} >>> ${nextState}`);
    displayState = nextState;

    //standalone will restrict to only running for an installed PWA on mobile
    //probably need to call function shouldShowUserPrompt to check diff when staus changes from active to passive
    if (nextState === "active") {
      // check the build
      await checkNetlifyBuild();
      checkActiveCohorts();

      // check cohort type -> update current cohort obj for the build without multiple cohorts
      if (
        Object.prototype.toString.call(authStore.cohort.value) !==
        "[object Array]"
      ) {
        console.log("authStore cohort value need to be updated: ");
        let activeCohorts = authStore.programmeObject.value.cohorts?.filter(
          (item) => item.isCurrent === true
        );
        authStore.cohort.value = activeCohorts;
        console.log(authStore.cohort.value, "authStore.cohort.value updated");
      }

      //&& isMobile /* && standalone */
      timer = setTimeout(() => {
        console.log(
          "TIME UP_ SHOULD SHOW",
          authStore.shouldShowUserPrompt.value
        );
        //check if we showed to the user prompts in the last 10 minutes because its annowing to show them so often
        // READ FROM AUTH STORE -> COMPARE WITH TIME NOW AND SEE IF LESS THAN 10 Minutes DONT TRIGGER THE CODE BELOW

        // subscribeToAllUpdates();
        console.log(sse, "sse");
        if (sse?.readyState === 2) {
          subscribeToAllUpdates();
        }
        if (prompter) {
          console.log(
            "prompter: onDisplayStateChange event, showPromptsWithoutFetch called"
          );
          prompter.showPromptsWithoutFetch();
        }
      }, 500);
    } else if (nextState === "hidden" && isMobile()) {
      localStorage.setItem("hiddenMobileTimestamp", new Date().toISOString());
    }

    // check for mobile: when the app is collapsed more than 30 min -> call the resync for some data (events + mentor's sessions)
    if (prevState === "hidden" && isMobile()) {
      // && nextState === "active"

      const timeStamp = localStorage.getItem("hiddenMobileTimestamp") || "";
      console.log(
        "hidden state is changed on active, hiddenMobileTimestamp: ",
        timeStamp
      );

      if (timeStamp) {
        // dispatch the event with timeStamp for hidden state
        window.dispatchEvent(
          new CustomEvent("mobile-hidden-timestamp", {
            detail: { timestamp: timeStamp },
            bubbles: true,
            composed: true,
          })
        );

        // save the timeStamp for events
        eventsStore.hiddenMobileTimestamp.value = timeStamp;
        console.log(eventsStore, 'eventsStore')
        sessionsStore.hiddenMobileTimestamp.value = timeStamp;
        // clear the hiddenMobileTimestamp
        localStorage.removeItem("hiddenMobileTimestamp");
      }
    }
  }
};

function isFunction(functionToCheck) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  );
}

let reconnectFrequencySeconds = 1;
let reconnectFunc = debounce(
  function () {
    subscribeToAllUpdates();
    // Double every attempt to avoid overwhelming server
    reconnectFrequencySeconds *= 2;
    // Max out at ~1 minute as a compromise between user experience and server load
    if (reconnectFrequencySeconds >= 64) {
      reconnectFrequencySeconds = 64;
    }
  },
  function () {
    return reconnectFrequencySeconds * 1000;
  }
);

function debounce(func, wait) {
  var timeout;
  var waitFunc;

  return function () {
    if (isFunction(wait)) {
      waitFunc = wait;
    } else {
      waitFunc = function () {
        return wait;
      };
    }
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, waitFunc());
  };
}

function subscribeToAllUpdates() {
  let reconnectFrequencySeconds = 1;
  console.log("subscribeToAllUpdates() called");
  console.log("COMPUTED ID", authStore.getComputedId.value);

  if (!authStore.cohort.value) {
    console.log("there is no authStore.cohort.value in subscribeToAllUpdates");
    return;
  }

  let activeCohortsIds = authStore.cohort.value
    ?.map((item) => item.id)
    .join(",");
  console.log(activeCohortsIds, "activeCohortsIds");

  if (sse && sse?.readyState === 1) {
    console.log("sse is working", sse);
    return;
  }
  console.log("sse in subscribeToAllUpdates: ", sse);

  sse = new EventSource(
    `${process.env.API_URL}/api/stream?collection=all&founder=${authStore.getComputedId.value}&token=${authStore.token.value}&programme=${authStore.programmeId.value}&cohorts=${activeCohortsIds}`
  );

  sse.addEventListener("message", (event) => {
    if (typeof event?.data === "string" && !event?.data?.includes("{")) {
      console.log("sse message", event?.data);
      return;
    }

    let { doc: record, operation, slug } = JSON.parse(event.data);
    console.log("SSE EVENT", slug);

    sessionsStore.sseMessage.value = { data: record, slug: slug };
    eventsStore.sseMessage.value = { data: record, slug: slug };

    window.dispatchEvent(
      new CustomEvent("sse-event", {
        detail: { data: record, slug: slug },
        bubbles: true,
        composed: true,
      })
    );

    if (slug === "prompts") {
      prompter.handleExternalSse(event);
    }
  });

  // sse.addEventListener("error", (e) => console.log(e));
  sse.onopen = function (e) {
    reconnectFrequencySeconds = 1;
  };
  sse.onerror = function (e) {
    console.log("SSE ERR", new Date());
    console.log(e);

    sse.close();
    reconnectFunc();
  };
}

window.addEventListener("offline", (event) => {
  console.log("OFFLINE");
  localStorage.setItem("offlineTimestamp", new Date().toISOString());
});

["pageshow", "focus", "blur", "visibilitychange", "resume"].forEach((type) => {
  window.addEventListener(type, onDisplayStateChange, { capture: true });
});

window.addEventListener("unload", async (e) => {
  if (timer) clearTimeout(timer);
  if (promptTimer) clearTimeout(promptTimer);
});

// window.addEventListener("vaadin-router-go", (e) => {
//   console.log("vaadin-router-go event, e:", e);
// });

//BEFORE LOCAL DB REMOVAL
// const initApp = async () => {
//   console.log("PROGRAMME ID ROUTER", authStore.programmeId.value);
//   const routes = filterPaths(
//     [
//       {
//         path: "/threads/(.*)",
//         action: (ctx, commands) => {
//           window.location.pathname = ctx.pathname;
//           window.location = "/threads.html";
//         },
//       },
//       {
//         path: "/editor-react/(.*)",
//         action: (ctx, commands) => {
//           posthog?.capture("$pageview");
//           window.location.pathname = ctx.pathname;
//         },
//       },
//       {
//         path: "/auth/(.*)",
//         action: (ctx, commands) => {
//           posthog?.capture("$pageview");
//           window.location.pathname = ctx.pathname;
//         },
//       },
//       {
//         path: "/logout",
//         action: async (context, commands) => {
//           console.log("LOGOUT BEING CALLED");
//           return await import("./pages/logout.js").then((module) => {
//             console.log("MODULE LOADED");
//             posthog?.capture("$pageview");
//             return new module.LogoutPage();
//           });
//         },
//       },
//       {
//         path: "/",
//         action: async (context, commands) => {
//           if (!authStore.token.value) {
//             window.location = "/auth.html";
//           }
//           await import("./pages/calendar-page/calendar-page.js");
//         },
//         component: "calendar-page",
//       },
//       {
//         path: "/calendar/public-event/:id",
//         action: async (context, commands) => {
//           // WIP
//           if (!authStore.token.value) {
//             window.location = "/auth.html";
//           }
//           return await import(
//             "./pages/share-public-event/share-public-event.js"
//           ).then((module) => {
//             return new module.SharePublicEvent(context.params.id);
//           });
//         },
//       },
//       {
//         path: "/calendar/private-event/:id",
//         action: async (context, commands) => {
//           if (!authStore.token.value) {
//             window.location = "/auth.html";
//           }

//           return await import(
//             "./pages/share-private-event/share-private-event.js"
//           ).then((module) => {
//             return new module.SharePrivateEvent(context.params.id);
//           });
//         },
//       },
//       {
//         path: "/startups",
//         action: async (context, commands) => {
//           if (!authStore.token.value) {
//             window.location = "/auth.html";
//           }
//           await import("./pages/startup-directories.js");
//         },
//         component: "startups-directories",
//       },
//       {
//         path: "/mentoring",
//         action: async (context, commands) => {
//           if (!authStore.token.value) {
//             window.location = "/auth.html";
//           }

//           console.log("RESOLVING STARTUPS ON MOBILE");

//           if (authStore.userRole.value === "mentor") {
//             return commands.redirect("/mentor-community");
//           }

//           // to open the tab when redirect from calendar page
//           let tabMentoring;
//           if (context.search === "?sessions") {
//             tabMentoring = true;
//           }
//           return await import("./pages/mentoring.js");
//         },
//         component: "mentoring-page",
//       },
//       {
//         path: "/startups/startup/:id",
//         action: async (context, commands) => {
//           if (!authStore.token.value) {
//             window.location = "/auth.html";
//           }
//           await import(
//             "./components/startup-menu-bottom/startup-menu-bottom.js"
//           );
//           let query = cohortDb.startupsCollection.findOne(context.params.id);
//           let startup = await query.exec();
//           console.log(startup, "startup");
//           return await import("./pages/startup-page.js").then((module) => {
//             return new module.StartupPage(startup, query);
//           });
//         },
//       },
//       {
//         path: "/mentor-community",
//         action: async (context, commands) => {
//           if (!authStore.token.value) {
//             window.location = "/auth.html";
//           }

//           const quantityOfStartups = await cohortDb.startupsCollection
//             ?.count()
//             .exec();
//           const startups = await cohortDb.startupsCollection
//             ?.find({ limit: 50 })
//             .exec();

//           let topics = await cohortDb.sessionsTopicCollection.find().exec();

//           // get current minutes for mentor
//           let mentor = authStore.userObject.value;

//           let activeProgramme = mentor.programmes.find((item) => {
//             return item.programme.id === authStore.programmeId.value;
//           });

//           // available minutes per programme
//           let mentorAvailableMinutes =
//             activeProgramme?.totalMentorAvailableMinutes;
//           let mentorTotalMinutes = activeProgramme?.totalMentorMinutes;
//           let mentorSessionDuration = activeProgramme?.sessionDuration;
//           let typeMinutesFounder =
//             activeProgramme?.programme?.settings?.founderAllowanceType ||
//             "programme";
//           let typeMinutesMentor =
//             activeProgramme?.programme?.settings?.mentorAllowanceType;

//           const sessionMinutes =
//             authStore.userObject.value.mentorMentoringProgrammeTopicMinutes;
//           console.log(
//             sessionMinutes,
//             "sessionMinutes",
//             authStore.userObject.value
//           );

//           posthog?.capture("$pageview", {
//             pathname: "/mentoring-community",
//             sessionMinutes,
//             mentorAvailableMinutes,
//             mentorTotalMinutes,
//           });
//           console.log("redirected page mentor");
//           return await import("./pages/mentor-community.js").then((module) => {
//             return new module.MentorCommunity(
//               startups,
//               cohortDb.rxdb,
//               quantityOfStartups,
//               sessionMinutes,
//               topics,
//               mentorAvailableMinutes,
//               mentorTotalMinutes,
//               mentorSessionDuration,
//               typeMinutesFounder,
//               typeMinutesMentor
//             );
//           });
//         },
//       },
//       {
//         path: "/settings-page",
//         action: async (context, commands) => {
//           if (!authStore.token.value) {
//             window.location = "/auth.html";
//           }
//           let userInfo, events, tab, userEvents, userQuery;
//           const userId = authStore.userId.value;

//           let userTags = await cohortDb?.userTagsCollection.find().exec();
//           events = await cohortDb?.eventsCollection
//             .find({
//               selector: {
//                 start: { $gte: new Date().toISOString() },
//               },
//               sort: [{ start: "asc" }],
//             })
//             .exec();

//           let currentProgrammeId = authStore.programmeId.value;

//           let code;

//           if (authStore.userRole.value === "startup") {
//             //let founderId = getFounderId();
//             //const startup = await getStartup();
//             userInfo = await cohortDb?.startupsCollection
//               .findOne(userId)
//               .exec();

//             // let founderQuery = await cohortDb?.startupsCollection.find().where('founders.id').eq(window.founder.id).exec();
//             //console.log(founderQuery, 'founderQuery')

//             userQuery = await cohortDb?.startupsCollection.findOne(
//               authStore.userId.value
//             );

//             userEvents = events.filter(
//               (doc) => doc.organizer?.founder?.id === authStore.userId.value
//             );
//             if (context.search === "?tab=editor") {
//               tab = 4;
//             }
//           } else {
//             console.log(authStore.userId.value, "user id");
//             userQuery = await cohortDb?.mentorsCollection.findOne(
//               authStore.userId.value
//             );

//             userInfo = await cohortDb?.mentorsCollection.findOne(userId).exec();

//             userEvents = events.filter(
//               (doc) => doc.organizer?.user?.id === authStore.userId.value
//             );

//             if (context.search.includes("?connection=calendly")) {
//               tab = 6;
//             }

//             if (context.search.includes("code")) {
//               code = context.search.split("code=")[1];
//             }

//             if (context.search === "?tab=editor") {
//               tab = 4;
//             }
//           }

//           // for redirection to edit event (from calendar page)
//           let eventId;
//           if (context.search.includes("edit-event")) {
//             eventId = context.search.split("edit-event=")[1];
//             tab = 16;
//           }

//           // for editor
//           let eventsQuery = await cohortDb?.eventsCollection?.find({
//             sort: [{ start: "asc" }],
//           });

//           return await import("./pages/settings-page/settings-page.js").then(
//             (module) => {
//               return new module.SettingsPage(
//                 cohortDb.rxdb,
//                 userInfo,
//                 userEvents,
//                 userTags,
//                 tab,
//                 userQuery,
//                 eventsQuery,
//                 code,
//                 currentProgrammeId,
//                 authStore.userRole.value,
//                 eventId
//               );
//             }
//           );
//         },
//         component: "settings-page",
//       },

//       {
//         path: "/resources",
//         action: async (context, commands) => {
//           if (!authStore.token.value) {
//             window.location = "/auth.html";
//           }
//           await import("./pages/resources.js");
//         },
//         component: "resources-page",
//       },
//       {
//         path: "/resource/:id",
//         action: async (context, commands) => {
//           if (!authStore.token.value) {
//             window.location = "/auth.html";
//           }
//           // let resource = await cohortDb.resourcesCollection
//           //   .findOne(context.params.id)
//           //   .exec();
//           posthog?.capture(
//             "$pageview",
//             { pathname: "/resource/:id" },
//             { resourceId: `${context.params.id}` }
//           );
//           await import("./pages/resource-page/resource-page.js");
//         },
//         component: "resource-page",
//       },
//       {
//         path: "/inbox",
//         action: async () => {
//           if (!authStore.token.value) {
//             window.location = "/auth.html";
//           }

//           posthog?.capture("$pageview", { pathname: "/inbox" });
//           await import("./pages/inbox-mobile-page.js");
//         },
//         component: "inbox-mobile-page",
//         children: [
//           {
//             path: "/:id",
//             component: "inbox-feed",
//             action: async () => {
//               posthog?.capture("$pageview", { pathname: "/inbox/:id" });
//               await import("../global-components/inbox/inbox-feed.js");
//             },
//           },
//         ],
//       },
//     ],
//     authStore.hasToken.value
//   );

//   await appRouter.setRoutes(routes);

//   let userQuery;
//   if (authStore.userRole.value === "mentor") {
//     userQuery = cohortDb.rxdb.mentors.findOne(authStore.userId.value);
//     userQuery.$.subscribe((results) => {
//       if (!results) return;
//       console.log(results, "results mentor");
//       authStore.userObject.value = results;
//       console.log("authStore.userObject UPDATED", authStore.userObject.value);

//       // check active cohort
//       checkActiveCohorts();

//       //saveUserObject(results);
//     });
//   }
//   if (authStore.userRole.value === "startup") {
//     userQuery = cohortDb.rxdb.startups.findOne(authStore.userId.value);
//     userQuery.$.subscribe((results) => {
//       if (!results) return;
//       console.log(results, "results");
//       authStore.userObject.value = results;
//       console.log("authStore.userObject UPDATED", authStore.userObject.value);
//       //saveUserObject(results);

//       // check active cohort
//       checkActiveCohorts();

//       // update founder info
//       let currentFounder = results.founders.find(
//         (item) => item.id === authStore.founderId.value
//       );
//       if (!currentFounder) return;
//       console.log(currentFounder, "currentFounder");
//       authStore.founderObject.value = currentFounder;
//       //saveFounderObjectLocalStorage(currentFounder);
//     });
//   }
// };
// getDB().then(async (res) => {
//   cohortDb = res;

//   if (authStore.userObject.value && authStore.userRole.value !== "mentor") {
//     console.log("YES USER OBJECT AND NOT MENTOR");

//     if (!authStore.founderId.value) {
//       console.log("NO FOUNDER ID");
//       if (cohortDb) {
//         console.log("YES THERE IS COHORTDB");
//         cohortDb?.clearDb();
//         return;
//       } else {
//           console.log("MANUAL CLEAN");
//           localStorage.clear();
//           window.location = "/auth.html";
//           return;
//       }
//     }
//   }

//     await cohortDb.addCollectionsToDB().then(async (col) => {
//       // console.log("LAST SYNC",getLastinSyncCalendar());
//       // if (!!getLastinSyncCalendar()) {
//       console.log("NO CALENDAR DATA");
//       cohortDb.calendarSub?.subscribe(async (value) => {
//         if (value && !value.error) {
//           await cohortDb.rxdb.calendar.bulkUpsert(value.docs);
//           saveLastinSyncCalendar(new Date().getTime());
//         }
//       });
//       //  }

//       // make a check for not correct sessions
//       let resetSessionsTimestamp = localStorage.getItem(
//         "clear_local_db_sessions"
//       );
//       console.log(
//         resetSessionsTimestamp,
//         "resetSessionsTimestamp from local storage"
//       );
//       if (!resetSessionsTimestamp) {
//         // mark not correct sessions as soft deleted in local db
//         let wrongSessions, allSessions;
//         cohortDb.rxdb.sessions
//           .find()
//           .exec()
//           .then((result) => {
//             allSessions = result;
//             console.log(allSessions, "allSessions of the user");
//             if (authStore.userRole.value === "mentor") {
//               wrongSessions = allSessions.filter(
//                 (item) => item.mentor.id !== authStore.userId.value
//               );
//             } else if (authStore.userRole.value === "startup") {
//               wrongSessions = allSessions.filter(
//                 (item) => item.founder.id !== authStore.founderId.value
//               );
//             }
//             console.log(wrongSessions, "not correct sessions");
//             if (wrongSessions && wrongSessions.length > 0) {
//               wrongSessions = wrongSessions.forEach((item) => item.remove());
//               console.log(wrongSessions, "wrongSessions updated");
//             }
//             // set timestamp
//             localStorage.setItem("clear_local_db_sessions", Date.now());
//           });
//       }

//       console.log("START REPL INDEX", cohortDb.eventsReplState);
//       cohortDb.eventsReplState = cohortDb.replicateEvents();
//       console.log("START REPL INDEX", cohortDb.eventsReplState);

//       cohortDb.startupsReplState = cohortDb.replicateStartups();
//       cohortDb.sessionstopicsReplState = cohortDb.replicateSessionsTopics();
//       cohortDb.eventstagsReplState = cohortDb.replicateEventsTags();
//       cohortDb.usertagsReplState = cohortDb.replicateUserTags();
//       cohortDb.eventscatReplState = cohortDb.replicateEventsCategories();
//       cohortDb.resourcesReplState = cohortDb.replicateResources();
//       cohortDb.resourcetopicsReplState = cohortDb.replicateResourcesTopics();
//       cohortDb.mentorsReplState = cohortDb.replicateMentors();
//       cohortDb.sessionsReplState = cohortDb.replicateSessions();
//       subscribeToAllUpdates();
//       checkActiveCohorts();
//       // authStore.cohort.value = getCohortObject();
//       initApp();

//       import(/* webpackPreload: true */ "../src/_data/prompter.js").then(
//         (module) => {
//           console.log("PROMPTER MODULE READY", module);
//           prompter = module.default();
//           if (authStore.token.value) {
//             prompter.attachDB(cohortDb.rxdb);
//             prompter.bootUp().then(() => {
//               console.log("prompter is boot up");
//               //prompter.subscribeToNewData();
//             });
//           }
//         }
//       );
//     });

// });

//BEFORE MAJOR WORKER REMOVAL
// getDB().then(async (res) => {
//   cohortDb = res;
//   if (authStore.hasToken.value) {
//     posthog.capture("user-init-db-data", {
//       role: authStore.userRole.value,
//       programme: authStore.programmeId.value,
//       founder: authStore.founderObject.value,
//       userInfo: authStore.userObject.value,
//     });
//
//     posthog.register({
//       role: authStore.userRole.value,
//       programme: authStore.programmeId.value,
//       founder: authStore.founderObject.value,
//       userInfo: authStore.userObject.value,
//     });
//   }
//
//   await res.rxdb.getLocal$("cohort").subscribe(documentOrNull => {
//     console.log("COHORT LOCAL DOCUMENT", documentOrNull);
//     if (documentOrNull !== null) {
//       authStore.cohort.value = documentOrNull.toJSON()?.data
//     }
//   });
//
//   cohortDb.addCollectionsToDB().then((col) => {
//     console.log(col);
//
//     import(/* webpackPreload: true */ "./_data/prompter").then((module) => {
//       console.log("PROMPTER MODULE READY", module);
//       prompter = module.default();
//       if (authStore.hasToken.value) {
//         prompter.attachDB(cohortDb.rxdb);
//         prompter.bootUp().then(() => {
//           console.log("prompter data + boot is ready");
//           prompter.subscribeToNewData();
//         });
//       }
//     });
//
//
//     // WHEN USER COMES TO PAGE WE UPDATE OUR AUTH STORE
//     // OBJECT INSTEAD OF LOCALSTORAGE WE WANT TO UPDATE FROM DB
//     let userQuery;
//     if (authStore.userRole.value === "mentor") {
//       userQuery = col.mentors.findOne(authStore.userId.value);
//       userQuery.$.subscribe((results) => {
//         if (!results) return;
//         console.log(results, "results mentor");
//         authStore.userObject.value = results;
//         console.log("authStore.userObject UPDATED", authStore.userObject.value);
//         saveUserObject(results);
//       });
//     } else if (authStore.userRole.value === "startup") {
//       userQuery = col.startups.findOne(authStore.userId.value);
//       userQuery.$.subscribe((results) => {
//         if (!results) return;
//         console.log(results, "results");
//         authStore.userObject.value = results;
//         console.log("authStore.userObject UPDATED", authStore.userObject.value);
//         saveUserObject(results);
//
//         // update founder info
//         let currentFounder = results.founders.find(
//           (item) => item.id === authStore.founderId.value
//         );
//         if (!currentFounder) return;
//         console.log(currentFounder, "currentFounder");
//         authStore.founderObject.value = currentFounder;
//         saveFounderObjectLocalStorage(currentFounder);
//       });
//     }
//
//     initApp();
//   });
// });
